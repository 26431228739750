export function defaultPagination(onShowSizeChange, total, pageSize) {
  return {
    hideOnSinglePage: false,
    defaultPageSize: pageSize || 20,
    showSizeChanger: false,
    showQuickJumper: true,
    pageSizeOptions: ["5", "10", "15", "20"],
    showTotal: total => `共 ${total} 条数据`,
    onShowSizeChange: onShowSizeChange,
    total: total || 0
  };
}

export function tokenOnlyPagination() {
  return {
    hideOnSinglePage: true,
    defaultPageSize: 20,
    showQuickJumper: false,
    showSizeChanger: false,
    showLessItems: true,
    total: 20
  };
}
