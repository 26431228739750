<!-- POST /repair/apply/list   维修申请列表 -->
<!-- GET /code/list  获取字典列表 -->

<template>
  <div>
    <div>
      <!-- 搜索框 -->
      <a-card style="margin-bottom: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 门店名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称:">
                    <a-input
                      style="overflow: hidden; text-overflow: ellipsis"
                      v-model="queryParam.facilityName"
                      placeholder="请输入"
                      @pressEnter="initTable"
                    />
                  </a-form-item>
                </a-col>
                <!-- 店铺名称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="店铺名称:">
                    <a-input
                      style="overflow: hidden; text-overflow: ellipsis"
                      v-model="queryParam.storeName"
                      placeholder="请输入"
                      @pressEnter="initTable"
                    />
                  </a-form-item>
                </a-col>
                <!-- 维修状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="维修状态:">
                    <a-select
                      style="width: 175px;margin-left: 0.5em;"
                      v-model="queryParam.status"
                      placeholder="请选择"
                      :allowClear="true"
                      @change="initTable"
                    >
                      <a-select-option
                        v-for="item in repairStatus"
                        :key="item.code"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 紧急程度 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="紧急程度:">
                    <a-select
                      style="width: 175px;margin-left: 0.5em;"
                      v-model="queryParam.urgency"
                      placeholder="请选择"
                      :allowClear="true"
                      @change="initTable"
                    >
                      <a-select-option
                        v-for="item in repairUrgency"
                        :key="item.code"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 维修类目 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="维修类目:">
                    <a-select
                      style="width: 175px;margin-left: 0.5em;"
                      v-model="queryParam.type"
                      placeholder="请选择"
                      :allowClear="true"
                      @change="initTable"
                    >
                      <a-select-option
                        v-for="item in repairType"
                        :key="item.code"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" class="fr">
                  <a-form-item>
                    <a-button @click="resetQueryParam">重置</a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
      </a-card>
      <a-spin :spinning="dataLoading">
        <!-- <a-card style="margin-bottom: 24px" v-if="tableData.length > 0"> -->
        <a-card style="margin-bottom: 24px">
          <!-- 账单显示表格列表 -->
          <a-row :gutter="0" style="line-height: 3">
            <a-table
              rowKey="applyId"
              :columns="columns"
              :dataSource="tableData"
              :pagination="pagination"
              @change="changePageSize"
              :scroll="{ x: 1300 }"
            >
              <span
                slot="urgencyName"
                slot-scope="text, record"
                class="blueColor"
              >
                <span v-if="record.urgencyName == '高'" class="red">高</span>
                <span v-else-if="record.urgencyName == '中'" class="yellow"
                  >中</span
                >
                <span v-else-if="record.urgencyName == '低'" class="green"
                  >低</span
                >
              </span>
              <span
                slot="statusName"
                slot-scope="text, record"
                class="blueColor"
              >
                <span v-if="record.statusName == '维修中'" class="green"
                  >维修中</span
                >
                <span v-else-if="record.statusName == '已完成'" class="gary"
                  >已完成</span
                >
                <span v-else-if="record.statusName == '验收中'" class="yellow"
                  >验收中</span
                >
              </span>
              <span slot="action" slot-scope="text, record" class="blueColor">
                <a @click="viewDetail(record)">查看</a>
              </span>
            </a-table>
          </a-row>
        </a-card>
      </a-spin>
    </div>
  </div>
</template>
<script>
  
import axios from "axios";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "repairOrderList",
  data() {
    return {
      // 搜索
      queryParam: {},
      // 维修状态
      repairStatus: [],
      // 紧急程度
      repairUrgency: [],
      // 维修类目
      repairType: [],
      // 分页
      pagination: defaultPagination(() => {}),
      pageSize: 20,
      current: 1,
      // 表头
      columns: [
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName"
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName"
        },
        {
          title: "档口编号",
          dataIndex: "kitchenCode",
          key: "kitchenCode"
        },
        {
          title: "维修类目",
          dataIndex: "typeName",
          key: "typeName"
        },
        {
          title: "紧急程度",
          key: "urgencyName",
          scopedSlots: { customRender: "urgencyName" }
        },
        {
          title: "维修申请日期",
          dataIndex: "applyDate",
          key: "applyDate"
        },
        {
          title: "维修完成日期",
          dataIndex: "repairDoneDate",
          key: "repairDoneDate"
        },
        {
          title: "验收通过日期",
          dataIndex: "verifyDate",
          key: "verifyDate"
        },
        {
          title: "维修状态",
          key: "statusName",
          scopedSlots: { customRender: "statusName" }
        },
        {
          title: "操作",
          key: "action",
          width: "200px",
          scopedSlots: { customRender: "action" }
        }
      ],
      // 数据
      tableData: [],
      // dataLoading
      dataLoading: false
    };
  },
  methods: {
    moment,
    initTable() {
      this.tableData = [];
      this.pageSize = 20;
      this.current = 1;
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.init();
    },
    // 重置页面
    resetQueryParam() {
      this.queryParam = {};
      this.tableData = [];
    },
    // 分页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init();
    },
    // 初始化
    init() {
      this.dataLoading = true;
      axios({
        url:   this.$baseUrl + "repair/apply/list",
        method: "POST",
        data: {
          rows: this.pageSize || 20,
          page: this.current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.tableData = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // 获取字典信息
    getRepairStatus() {
      axios({
        url:   this.$baseUrl + "code/list",
        method: "GET",
        params: {
          codeType: "repairStatus"
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.repairStatus = res.data.rows;
        }
      });
    },
    getRepairUrgency() {
      axios({
        url:   this.$baseUrl + "code/list",
        method: "GET",
        params: {
          codeType: "urgency"
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.repairUrgency = res.data.rows;
        }
      });
    },
    getRepairType() {
      axios({
        url:   this.$baseUrl + "code/list",
        method: "GET",
        params: {
          codeType: "repairType"
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.repairType = res.data.rows;
        }
      });
    },
    // 跳转详情页面
    viewDetail(val) {
      this.$router.push({
        path: "/store/repairOrderList/repairOrderDetail",
        query: {
          applyId: val.applyId
        }
      });
    }
  },
  created() {
    // 初始化城市列表
    this.getRepairStatus();
    this.getRepairUrgency();
    this.getRepairType();
    this.init();
  },
  computed: {}
};
</script>
<style lang="less" scoped>
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.green {
  color: #52c41a;
}
.yellow {
  color: #fb9e19;
}
.gary {
  color: #979797;
}
.red {
  color: #f5222d;
}
</style>
